import { Button, ButtonGroup, Card, Checkbox, DataTable, Inline, Text } from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import useAppDispatch from "hooks/useAppDispatch";
import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { selectCategory5 } from "redux/features/declarations/declarationsSlice";
import formatRow from "utils/formatRow";

const SubcategoryStep5 = ({ toNextStep, toPrevStep }) => {
	const dispatch = useAppDispatch();
	const {
		eprDeclaration: { subcategories_data },
		eprSubcategories: { selectedCategory5, selectedCategory4 },
	} = useSelector((state) => state.declarations);

	const handleChange = (
		checked,
		category,
		subCategory,
		subSubCategory,
		subSubSubCategory,
		subSubSubSubCategory
	) => {
		dispatch(
			selectCategory5({
				checked,
				category,
				subCategory,
				subSubCategory,
				subSubSubCategory,
				subSubSubSubCategory,
			})
		);
	};

	const columns = [
		{
			header: {
				label: "Subcategory 1",
			},
			cell: {
				formatters: [
					(_, { rowData }) => rowData[0],
				],
			},
		},
		{
			header: {
				label: "Subcategory 2",
			},
			cell: {
				formatters: [
					(_, { rowData }) => rowData[1],
				],
			},
		},
		{
			header: {
				label: "Subcategory 3",
			},
			cell: {
				formatters: [
					(_, { rowData }) => rowData[2],
				],
			},
		},
		{
			header: {
				label: "Subcategory 4",
			},
			cell: {
				formatters: [
					(_, { rowData }) => rowData[3],
				],
			},
		},
		{
			header: {
				label: "Subcategory 5",
			},
			cell: {
				formatters: [
					(_, { rowData }) => {
						const category = rowData[0];
						const subCategory = rowData[1];
						const subSubCategory = rowData[2];
						const subSubSubCategory = rowData[3];
						const checkboxes = subcategories_data.subcategory_5.Import;
						console.log('subcategories_data', subcategories_data)
						return (
							<Inline wrap spacing='4'>
					        {
										// Object.keys(checkboxes).map((categoryKey) => {
						        // const checkboxArray = checkboxes[categoryKey];
						        // console.log('checkboxes[categoryKey]:', checkboxArray);

						        // checkboxArray.map((checkbox) => {
							        checkboxes.map((checkbox) => {
							        const id = checkbox + subCategory + subSubCategory + subSubSubCategory + category;
							        console.log('Rendering checkbox with id:', id);
							        console.log('and checkbox:', checkbox);

							        return (
								        <Checkbox
									        key={id}
									        id={id}
									        value={checkbox}
									        label={checkbox}
									        checked={selectedCategory5.some(
										        (c) =>
											        c[1] === subCategory &&
											        c[2] === subSubCategory &&
											        c[3] === subSubSubCategory &&
											        c[4] === checkbox
									        )}
									        onChange={(checked) =>
										        handleChange(
											        checked,
											        category,
											        subCategory,
											        subSubCategory,
											        subSubSubCategory,
											        checkbox
										        )
									        }
								        />
							        );
						        // });
					        })}
					    </Inline>
						);

					},
				],
			},
		},
	];

	const rows = formatRow(selectedCategory4, columns);

	return (
		<>
            <Card sectioned>
                <DataTable
	                columnContentTypes={["text", "text", "text", "text", "text"]}
	                headings={columns.map(({ header }) => (
		                <Text key={header.label} fontWeight='semibold'>{header.label}</Text>
	                ))}
	                rows={rows}
                />
            </Card>

            <SaveBar>
                <ButtonGroup>
                    <Button onClick={toPrevStep}>{t("eprReporting.back")}</Button>
                    <Button primary onClick={toNextStep}>
                        {t("orderReg.next")}
                    </Button>
                </ButtonGroup>
            </SaveBar>
        </>
	);
};

export default SubcategoryStep5;
